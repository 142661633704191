<template>
  <section class="whychoose-1 secpadd">
    <div class="container">
      <div class="row quote1top">
        <div class="col-md-8">
          <div class="fh-section-title clearfix  text-left version-dark paddbtm40">
            <h2>{{ getTranslation('shipmentTracking') }}</h2>
          </div>
          <p>{{ getTranslation('trackingDesc') }}</p>
        </div>
        <div class="col-md-4">
          <img src="@/assets/portal/images/resources/cargocar.png" alt="">
        </div>
      </div>
      <div class="row margtop30">

        <div :class="{'col-md-9 col-sm-9':app.number || (app.status === 'ACCEPTED' || app.status === 'CONFIRMED')}">
          <div class="margbtm50" :class="{'col-md-12 col-sm-12': app.status !== 'ACCEPTED','col-md-9 col-sm-9':app.status==='ACCEPTED' && $store.getters['portal/getPortalUserId']}">
            <ul class="steps">
              <li class="step" :class="{'step-success':steps.first,'step-active':currentStep===1}">
                <div class="step-content">
                  <span class="step-circle">1</span>
                  <span class="step-text">{{ getTranslation('appAccepted') }}</span>
                  <span v-if="currentStep===1 || steps.first" class="step-text-description text-center">
                  {{ getTranslation('We have seen your app, in queue for conirmation') }}
                </span>
                </div>
              </li>
              <li class="step" :class="{'step-success':steps.second,'step-active':currentStep===2}">
                <div class="step-content">
                  <span class="step-circle">2</span>
                  <span class="step-text">{{ getTranslation('appInAction') }}</span>
                  <span v-if="currentStep===2 || steps.second" class="step-text-description text-center">
                  {{ getTranslation('Your app in confirmation') }}
                </span>
                </div>
              </li>
              <li class="step" :class="{'step-success':steps.third,'step-active':currentStep===3}">
                <div class="step-content">
                  <span class="step-circle">3</span>
                  <span class="step-text">{{ getTranslation('appConfirmed') }}</span>
                  <span v-if="currentStep===3 || steps.third" class="step-text-description text-center">
                  {{ getTranslation('Your app is confirmed, you need to agree with the price') }}
                </span>
                </div>
              </li>
              <li class="step" :class="{'step-success':steps.fourth,'step-active':currentStep===4}">
                <div class="step-content">
                  <span class="step-circle"><i class="fas fa-check-circle"></i></span>
                  <span class="step-text" v-if="!app.payment">{{ getTranslation('payment') }}</span>
                  <span class="step-text" v-if="app.payment">{{ getTranslation('paid') }}</span>
                  <span v-if="app.payment && steps.fourth" class="step-text-description text-center">
                  {{ getTranslation('Your app is paid') }}
                </span>
                  <span v-if="!app.payment && currentStep === 4" class="step-text-description text-center">
                  {{ getTranslation('You need to make payment') }}
                </span>
                </div>
              </li>
              <li class="step" :class="{'step-success':steps.five,'step-active':currentStep===5}">
                <div class="step-content">
                  <span class="step-circle"><i class="fas fa-check-circle"></i></span>
                  <span class="step-text">{{ getTranslation(app.status === 'ACCEPTED' ? 'confirmation' : (app.status === 'CONFIRMED' ? 'confirmed' : 'confirmation')) }}</span>
                  <span v-if="currentStep===5 || steps.five" class="step-text-description text-center">
                  {{ getTranslation('Your app is paid, and confirmed for shipment') }}
                </span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-md-3 col-sm-3 margbtm50">

          <SidebarWidget v-if="$route.params.type==='app'" :app="app" show-profile/>

          <div v-if="$route.params.type !== 'app' && (app.number && app.status === 'ACCEPTED' || app.status === 'CONFIRMED')" class="tracksidebar section align-items-center">
            <InvoiceTarifComponent :app="app"/>
          </div>

        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="panel panel-default">
            <div class="panel-body">
              <div class="col-md-6 col-sm-12">
                <span class="text-muted">{{ getTranslation('from') }}</span>
                <span class="fh-icon ml-2"><i class="fas" :class="{'fa-truck-moving':app.by_land,'fa-plane':!app.by_land}"></i></span>
                <h5 v-if="app.originCountry">{{ app.originCountry.fullText }}</h5>
                <span v-if="app.originCity" class="text-muted small">{{ getTranslation('originCity') }}</span>
                <h6 v-if="app.originCity">{{ app.originCity.fullText }}</h6>
              </div>
              <div class="col-md-6 col-sm-12">
                <span class="text-muted">{{ getTranslation('to') }}</span>
                <h5 v-if="app.destCountry">{{ app.destCountry.fullText }}</h5>
                <span v-if="app.destCity" class="text-muted small">{{ getTranslation('destinationCity') }}</span>
                <h6 v-if="app.destCity">{{ app.destCity.fullText }}</h6>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-sm-12">
          <div class="panel panel-default">
            <div class="panel-body">

              <div class="col-md-6 col-sm-12">
                <span v-if="app.provider" class="text-muted">{{ getTranslation('date') }}</span>
                <span v-if="app.provider" class="fh-icon ml-2"><i class="fa fa-calendar-check"></i></span>
                <h5 v-if="app.provider">{{ app.provider.day }} / {{ app.provider.date }}</h5>

              </div>
              <div class="col-md-6 col-sm-12">
                <span class="text-muted small">{{ getTranslation('serviceCategory') }}</span>
                <h6>{{ getTranslation(app.serviceCategory.slug) }}</h6>
                <span class="text-muted small">{{ getTranslation('serviceOptions') }}</span>
                <h6>{{ getTranslation(app.by_land ? app.serviceOption.landText : app.serviceOption.airText) }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="priceInfo.latestActivePrice" class="row">
        <div class="col-md-12">
          <div class="panel panel-default">
            <div class="panel-body">
              <div class="col-sm-3">
                <div class="c-callout c-callout-danger">
                  <small class="text-muted"> {{ getTranslation('price') }} </small><br>
                  <strong v-if="app.provider" class="h4">{{ app.provider.totalPrice }} {{ app.provider.currency }}</strong>
                </div>
              </div><!--/.col-->
              <div class="col-sm-3">
                <div class="c-callout c-callout-primary">
                  <small class="text-muted">{{ getTranslation('priceWeight') }} </small><br>
                  <strong class="h4">{{ priceInfo.latestActivePrice.price_weight }} <span v-if="app.totals">{{ app.totals.w_unit }}</span> </strong>
                </div>
              </div><!--/.col-->
              <div class="col-sm-3">
                <div class="c-callout c-callout-primary">
                  <small class="text-muted">{{ getTranslation('priceTransport') }} </small><br>
                  <strong class="h4">{{ priceInfo.latestActivePrice.price_transport }} <span v-if="app.provider">{{ app.provider.currency }}</span> </strong>
                </div>
              </div><!--/.col-->
              <div class="col-sm-3">
                <div class="c-callout c-callout-primary">
                  <small class="text-muted">{{ getTranslation('priceAwb') }} </small><br>
                  <strong class="h4">{{ priceInfo.latestActivePrice.price_awb }} <span v-if="app.provider">{{ app.provider.currency }}</span> </strong>
                </div>
              </div><!--/.col-->
            </div>
          </div>

        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="panel panel-default">
            <!--         sender contact   -->
            <div class="panel-heading">
              <span class="panel-title">{{ getTranslation('contact_type_sender') }} <span v-if="contacts.sender.id">- {{ contacts.sender.name }}</span></span>
              <div class="btn-group btn-group-xs float-right">
                <button v-if="!contacts.sender.id" class="btn btn-xs btn-info" @click="openModal('sender')">{{ getTranslation('add') }}</button>
              </div>
            </div>
            <div v-if="contacts.sender.id" class="panel-body">
              <div class="col-md-6 col-sm-12">
                <span class="text-muted small">{{ getTranslation('pin') }}</span>
                <h6>{{ contacts.sender.pin || '-' }}</h6>
                <span class="text-muted small">{{ getTranslation('email') }}</span>
                <span class="fh-icon ml-2"><i class="fa fa-envelope"></i></span>
                <h6>{{ contacts.sender.email || '-' }}</h6>
              </div>
              <div class="col-md-6 col-sm-12">
                <span class="text-muted small">{{ getTranslation('mobile') }}</span>
                <span class="fh-icon ml-2"><i class="fa fa-mobile"></i></span>
                <h6>
                  <span v-if="contacts.sender.mobileCode">{{ contacts.sender.mobileCode.code }}</span>
                  {{ contacts.sender.mobile || '-' }}
                </h6>
                <span class="text-muted small">{{ getTranslation('whatsapp') }}</span>
                <span class="fh-icon ml-2"><i class="fab fa-whatsapp"></i></span>
                <h6>
                  <span v-if="contacts.sender.whatsapp && contacts.sender.whatsappCode">{{ contacts.sender.whatsappCode.code }}</span>
                  {{ contacts.sender.whatsapp || '-' }}
                </h6>
              </div>
            </div>
            <!--         origin address   -->
            <div class="panel-body">
              <div class="col-md-12 col-sm-12">
                <span class="text-muted">{{ getTranslation('originAddress') }}</span>
                <span class="fh-icon ml-2"><i class="fa fa-location-arrow" aria-hidden="true"></i></span>
                <p class="text" style="color: #000">
                  {{ app.originAddress }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-sm-12">
          <div class="panel panel-default">
            <!--         receiver contact   -->
            <div class="panel-heading">
              <span class="panel-title">{{ getTranslation('contact_type_receiver') }} <span v-if="contacts.receiver.id">- {{ contacts.receiver.name }}</span></span>
              <div class="btn-group btn-group-xs float-right">
                <button v-if="!contacts.receiver.id" class="btn btn-xs btn-info" @click="openModal('receiver')">{{ getTranslation('add') }}</button>
              </div>
            </div>
            <div v-if="contacts.receiver.id" class="panel-body">
              <div class="col-md-6 col-sm-12">
                <span class="text-muted small">{{ getTranslation('pin') }}</span>
                <h6>{{ contacts.receiver.pin || '-' }}</h6>
                <span class="text-muted small">{{ getTranslation('email') }}</span>
                <span class="fh-icon ml-2"><i class="fa fa-envelope"></i></span>
                <h6>{{ contacts.receiver.email || '-' }}</h6>
              </div>
              <div class="col-md-6 col-sm-12">
                <span class="text-muted small">{{ getTranslation('mobile') }}</span>
                <span class="fh-icon ml-2"><i class="fa fa-mobile"></i></span>
                <h6>
                  <span v-if="contacts.receiver.mobileCode">{{ contacts.receiver.mobileCode.code }}</span>
                  {{ contacts.receiver.mobile || '-' }}
                </h6>
                <span class="text-muted small">{{ getTranslation('whatsapp') }}</span>
                <span class="fh-icon ml-2"><i class="fab fa-whatsapp"></i></span>
                <h6>
                  <span v-if="contacts.receiver.whatsapp && contacts.receiver.whatsappCode">{{ contacts.receiver.whatsappCode.code }}</span>
                  {{ contacts.receiver.whatsapp || '-' }}
                </h6>
              </div>
            </div>
            <!--         destination address   -->
            <div class="panel-body">
              <div class="col-md-12 col-sm-12">
                <span class="text-muted">{{ getTranslation('destinationAddress') }}</span>
                <span class="fh-icon ml-2"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                <p class="text" style="color: #000">
                  {{ app.destAddress }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-sm-12">

          <div class="panel panel-default">
            <div class="panel-heading">
              {{ app.title }}
              <span class="badge badge-info ml-2" v-if="app.by_land">{{ getTranslation('appByLand') }}</span>
              <span class="badge badge-info ml-2" v-if="app.needs_border_service">{{ getTranslation('appNeedsBorderService') }}</span>
            </div>
            <div class="panel-body table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th style="width: 10%">{{ getTranslation('docNumber') }}</th>
                  <th style="width: 10%">{{ getTranslation('pieces') }}</th>
                  <th style="width: 10%">{{ getTranslation('weight') }}</th>
                  <th style="width: 10%">{{ getTranslation('volume') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{{ app.code }}</td>
                  <td>{{ app.totals.totalQuantity }}</td>
                  <td>{{ app.totals.totalWeight }} {{ app.totals.w_unit }}</td>
                  <td>{{ app.totals.totalVolume_m }} m<sup>3</sup></td>
                </tr>
                </tbody>
              </table>

              <table class="table margtop30" v-if="items.length > 0">
                <thead>
                <tr>
                  <th style="width: 15%">{{ getTranslation('airport') }}</th>
                  <th style="width: 15%">{{ getTranslation('date') }}</th>
                  <th style="width: 15%">{{ getTranslation('status') }}</th>
                  <th style="width: 30%">{{ getTranslation('details') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,key) in items" v-bind:key="key">
                  <td>{{ item.airport.fullText }}</td>
                  <td>{{ item.date }}</td>
                  <td>{{ item.status.title }}</td>
                  <td>{{ item.comment }}</td>
                </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
    </div>
    <PortalModal
        v-model="showModal"
        :modal-title="getTranslation('addContacts')"
        :ok-button-label="getTranslation('ok')"
        :cancel-button-label="getTranslation('cancel')"
        @ok-button-click="save"
        @cancelButtonClick="closeModal"
        @input="closeModal"
    >
      <form class="form fh-form">
        <div class="form-group">
          <label class="form__label--block form__label">{{ getTranslation('contactName') }} <span
              class="required">*</span></label>
          <input type="text" class="form-control" v-model="contactForm.name" :class="{'is-invalid':$v.contactForm.name.$error}">
        </div>
        <div class="form-group">
          <label class="form__label--block form__label">{{ getTranslation('pin') }} <span
              class="required">*</span></label>
          <input type="text" class="form-control" v-model="contactForm.pin" :class="{'is-invalid':$v.contactForm.pin.$error}">
        </div>
        <div class="form-group">
          <label class="form__label--block form__label">{{ getTranslation('type') }} <span
              class="required">*</span></label>
          <v-select
              :options="types"
              :reduce="option=>option.id"
              label="title"
              v-model="contactForm.type"
              :class="{'is-invalid':$v.contactForm.type.$error}"
              :placeholder="getTranslation('selectFromList')"
              disabled
          />
        </div>
        <div class="form-group">
          <label>{{ getTranslation('mobile') }} <span class="required">*</span> </label>
          <div v-if="showPhonCodes" class="input-group">

            <PhoneCode required :edit-item-id="contactForm.mobile_code_id" v-model="contactForm.mobile_code_id" :items="phoneCodes"/>

            <input type="text" class="form-control" v-model="contactForm.mobile">
          </div>
        </div>
        <div class="form-group">
          <label>{{ getTranslation('whatsapp') }} </label>
          <div v-if="showPhonCodes" class="input-group">

            <PhoneCode required :edit-item-id="contactForm.whatsapp_code_id" v-model="contactForm.whatsapp_code_id" :items="phoneCodes"/>

            <input type="text" class="form-control" v-model="contactForm.whatsapp">
          </div>
        </div>
        <div class="form-group">
          <label class="form__label--block form__label">{{ getTranslation('email') }} <span class="required">*</span></label>
          <input type="email" class="form-control" :class="{'is-invalid':$v.contactForm.email.$error}" v-model="contactForm.email">
        </div>
      </form>
    </PortalModal>
  </section>
</template>

<script>
import SidebarWidget from "./client/components/SidebarWidget";
import required from "vuelidate/lib/validators/required";
import PortalModal from "@/views/portal/components/PortalModal";
import PhoneCode from "@/components/shared/PhoneCode";
import phoneMixin from "@/components/mixins/PhoneCode";
import InvoiceTarifComponent from "@/views/portal/components/InvoiceTarifComponent";
import tarifMixin from "@/views/portal/mixins/tarifRequest";

export default {
  name: "Tracking",
  components: { InvoiceTarifComponent, PortalModal, SidebarWidget, PhoneCode },
  mixins: [ phoneMixin, tarifMixin ],
  data() {
    return {
      contacts: {
        receiver: {},
        sender: {},
        contacter: {},
      },
      app: {},
      priceInfo: {},
      items: [],
      steps: {
        first: false,
        second: false,
        third: false,
        fourth: false,
        five: false
      },
      currentStep: 0,
      types: [],
      showModal: false,
      contactForm: {
        id: null,
        name: '',
        pin: '',
        type: '',
        email: '',
        mobile: '',
        mobile_code_id: 118,
        whatsapp: '',
        whatsapp_code_id: 118,
      }
    }
  },
  created() {

    if ( this.$store.getters['portal/getApp'].number ) {
      this.app = this.$store.getters['portal/getApp'];

      this.getPriceInfo(this.app.number);

      this.setSteps(this.app.status);

      this.fetchTracking(this.app.code).then(() => {
        this.fetchContactTypes();
        this.fetchContact();
      });

    } else
      this.$router.push({ path: '/' });
  },
  methods: {

    getPriceInfo(appId) {
      this.axios.get(this.$urls.portal.priceInfo.url.replace(':id', this.app.number))
          .then(resp => {
            this.priceInfo = resp.data.data;
          }).catch(() => {
      });
    },

    closeModal() {
      this.showModal = false;
      this.$v.$reset();
      this.contactForm = {
        id: null,
        name: '',
        type: '',
        email: '',
        pin: '',
        mobile: '',
        mobile_code_id: 118,
        whatsapp: '',
        whatsapp_code_id: 118,
      };
    },
    openModal(contactType) {
      this.showModal = true;
      this.contactForm.type = contactType === 'sender' ? 2 : 3;
    },
    save() {
      this.$v.contactForm.$touch();

      if ( this.$v.contactForm.$invalid )
        return this.snotifyError('wrongFormMsg');

      this.axios.post(this.$urls.portal.addContact.url.replace(':id', this.app.number), this.contactForm)
          .then(() => {
            this.snotifySuccess('saveSuccessMsg');
            this.fetchContact();
            this.closeModal();
          }).catch(() => {
      });

    },
    fetchContactTypes() {
      return this.axios.get(this.$urls.portal.contactTypes.url)
          .then(res => {
            this.types = this.objectToArray(res.data.data.contactTypes, true, true);
          }).catch(() => {
          });
    },

    fetchContact() {
      this.axios.get(this.$urls.portal.contacts.url.replace(':id', this.app.number))
          .then(res => {
            this.contacts.contacter = res.data.data[0];


            let senderArr = res.data.data.filter(item => {
              return item.type === 2;
            });

            if ( senderArr.length > 0 )
              this.contacts.sender = senderArr[0];

            let receiverArr = res.data.data.filter(item => {
              return item.type === 3;
            });

            if ( receiverArr.length > 0 )
              this.contacts.receiver = receiverArr[0];

          }).catch(() => {
      });
    },

    setSteps(status) {
      switch ( status ) {
        case 'SENT':
          this.currentStep = 1;
          break;
        case 'IN_ACTION':
          this.steps.first = true;
          this.currentStep = 2;
          break;
        case 'ACCEPTED':
          this.steps.first = true;
          this.steps.second = true;
          this.steps.third = true;

          if ( this.app.payment ) {
            this.currentStep = 5;
            this.steps.fourth = true;
          } else
            this.currentStep = 4;

          break;
        case 'CONFIRMED':
          this.steps.first = true;
          this.steps.second = true;
          this.steps.third = true;
          this.steps.fourth = true;
          this.steps.five = true;

          break;
      }
    },
    fetchTracking(code) {
      return this.axios.post(this.$urls.portal.tracking.url, { code: code, checkExpiration: this.$route.params.type === 'app' ? 0 : 1 })
          .then(response => {
            this.items = response.data.data;
          }).catch(() => {
          });
    }
  },
  validations: {
    tarifForm: {
      tarif: {
        required
      },
      comment: {
        required
      }
    },
    contactForm: {
      mobile: {
        required
      },
      mobile_code_id: {
        required
      },
      email: {
        required
      },
      name: {
        required
      },
      type: {
        required
      },
      pin: {
        required
      }
    }
  }
}
</script>

<style scoped>

</style>